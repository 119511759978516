import React, { Fragment } from 'react'
import { Container, CardBody, Input, Col, Form, Row, Label } from 'reactstrap'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Link } from 'react-router-dom'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import {
  SendAnInviteToThisContributor,
  DeleteQuestion,
  Save,
} from '../../Constant'
import GoogleFontLoader from 'react-google-font-loader'
import '../Button.css'

import ImageResize from 'quill-image-resize-module-react'
Quill.register('modules/imageResize', ImageResize)

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.quill = React.createRef()
    this.state = {
      text: '',
      imagePreviews: [],
      selectedOption: '',
      numCols: 1,
      onAdd: false,
      selectedQuestionData: '',
      showInputField: false,
      selectedCategory: '',
      showSecondDropdown: false,
      selectedContributor: '',
      contributor: [],
      isEditing: false,
      contributor_name: '',
      email: '',
      content: '',
    }
    this.h2Ref = React.createRef()
    this.toggleEdit = this.toggleEdit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.imageHandler = this.imageHandler.bind(this)
    this.saveCursorPosition = this.saveCursorPosition.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  async componentDidMount() {
    this.startUpdateInterval()
    const questionId = localStorage.getItem('selectedQuestionId')
    const response = await this.props.listQuestionDetails({ id: questionId })
    const questionData = response.payload
    const contributorName = questionData.contributor
    let contributorList = localStorage.getItem('contributor')
    let response1 = await this.props.contributorNameList()
    if (response1.payload.length !== 0) {
      const searchString = JSON.stringify(response1.payload)
      localStorage.setItem('contributor', searchString)
      contributorList = JSON.parse(searchString)
    }
    const selectedContributor = contributorList?.find(
      (contributor) => contributor.name === contributorName
    )
    this.setState({
      selectedQuestionData: questionData,
      selectedOption: questionData.status,
      text: questionData.answer,
      content: questionData.question,
      contributor: contributorList,
      selectedContributor: selectedContributor ? selectedContributor.id : '',
      showSecondDropdown: selectedContributor ? true : false,
    })

    // Add Tooltip Logic for Quill Toolbar
    setTimeout(() => {
      const toolbar = document.querySelector('.ql-toolbar')
      if (toolbar) {
        toolbar.querySelector('.ql-bold')?.setAttribute('data-tooltip', 'Bold')
        toolbar
          .querySelector('.ql-italic')
          ?.setAttribute('data-tooltip', 'Italic')
        toolbar
          .querySelector('.ql-underline')
          ?.setAttribute('data-tooltip', 'Underline')
        toolbar
          .querySelector('.ql-strike')
          ?.setAttribute('data-tooltip', 'Strikethrough')
        toolbar
          .querySelector('.ql-blockquote')
          ?.setAttribute('data-tooltip', 'Blockquote')
        toolbar
          .querySelector('.ql-clean')
          ?.setAttribute('data-tooltip', 'Clear Formatting')
        toolbar
          .querySelector('.ql-link')
          ?.setAttribute('data-tooltip', 'Insert Link')
        toolbar
          .querySelector('.ql-image')
          ?.setAttribute('data-tooltip', 'Insert Image')
      }
      // Tooltip for indent buttons
      const indentButtons = toolbar.querySelectorAll('.ql-indent')
      indentButtons.forEach((button) => {
        if (button.value === '-1') {
          button.setAttribute('data-tooltip', 'Decrease Indent') // Outdent button
        } else if (button.value === '+1') {
          button.setAttribute('data-tooltip', 'Increase Indent') // Indent button
        }
      })

      // Tooltip for align buttons
      const alignButtons = toolbar.querySelectorAll('.ql-picker-label')
      alignButtons.forEach((button) => {
        const buttonValue = button.nextElementSibling
          .querySelector('.ql-picker-item')
          .getAttribute('data-value')

        switch (buttonValue) {
          case 'center':
            button.setAttribute('data-tooltip', 'Align Center')
            break
          case 'right':
            button.setAttribute('data-tooltip', 'Align Right')
            break
          case 'justify':
            button.setAttribute('data-tooltip', 'Justify Align')
            break
          default:
            button.setAttribute('data-tooltip', 'Align Left')
            break
        }
      })
    }, 100)
  }

  handleChange(value) {
    this.setState({ text: value })
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  startUpdateInterval() {
    this.intervalId = setInterval(() => {
      this.updateAutomatically()
    }, 5000) // 5 seconds interval
  }

  updateAutomatically = async () => {
    try {
      const { selectedQuestionData, selectedOption, text, content } = this.state

      // Conditionally set the status to 'Ongoing' only if it's not 'Complete'
      let statusToUpdate =
        selectedOption === 'Not Started' || selectedOption === 'Complete'
          ? selectedOption
          : 'Ongoing'

      let updateResponse = await this.props.updateQuestion({
        id: selectedQuestionData.id,
        question: content,
        status: selectedOption,
        answer: text,
        contributor_name: selectedQuestionData.contributor,
        email: this.state.email,
      })

      this.setState({
        selectedOption: statusToUpdate,
      })
    } catch (err) {
      console.log(err)
    }
  }

  onUpdate = async (e) => {
    this.toggleEdit()
    e.preventDefault()

    try {
      const { selectedQuestionData, selectedOption, text, content } = this.state

      this.setState({ isEditing: false })

      let updateResponse = await this.props.updateQuestion({
        id: selectedQuestionData.id,
        question: content,
        status: selectedOption,
        answer: text,
        contributor_name: selectedQuestionData.contributor,
        email: this.state.email,
      })

      toast.success(updateResponse.message, { autoClose: 10000 })
    } catch (err) {
      toast.error(err.updateResponse?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  onDeleteClick = async (e) => {
    e.preventDefault()
    const confirmed = window.confirm('Are you sure, you want to delete it?')
    if (!confirmed) return
    try {
      let questionId = e.target.getAttribute('data-test-id')
      const response = await this.props.deleteQuestion({ id: questionId })
      toast.success(response.message, { autoClose: 10000 })
      window.open('/my-stories/view-question/', '_self')
    } catch (err) {
      console.log(err)
    }
  }

  onSendInvite = async () => {
    try {
      const { selectedContributor, contributor } = this.state
      if (!selectedContributor) {
        console.error('No contributor selected.')
        return
      }
      // Find the contributor details using the ID
      const selectedContributorDetails = contributor.find(
        (cont) => cont.id === parseInt(selectedContributor, 10)
      )
      if (!selectedContributorDetails) {
        console.error('Selected contributor details not found.')
        return
      }
      const questionId = localStorage.getItem('selectedQuestionId')
      const payload = {
        name: selectedContributorDetails.name,
        email: selectedContributorDetails.email,
        question_id: questionId,
      }
      const response = await this.props.SendAnInviteToThisContributor(payload)
      toast.success(response.message, { autoClose: 10000 })
    } catch (err) {
      console.error('Error sending invitation:', err)
      toast.error(err.response?.data?.message, {
        autoClose: 10000,
      })
    }
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onInsertContributor = async (event) => {
    event.preventDefault()
    try {
      const questionId = localStorage.getItem('selectedQuestionId')
      const payload = {
        name: this.state.contributor_name,
        email: this.state.email,
        question_id: questionId,
      }
      const response = await this.props.addContributor(payload)
      toast.success(response.message, { autoClose: 10000 })
    } catch (error) {
      console.error('Error sending invitation:', error.response.data.message)
      toast.error(
        error.response?.data?.message,
        { autoClose: 10000 },
        {
          autoClose: 10000,
        }
      )
    }
  }

  handleContributorChange = (event) => {
    const selectedContributorId = event.target.value
    const selectedContributor = this.state.contributor.find(
      (contributor) => contributor.id === parseInt(selectedContributorId, 10)
    )
    this.setState(
      {
        selectedContributor: selectedContributorId, // Update state with the ID
        selectedQuestionData: {
          ...this.state.selectedQuestionData,
          contributor: selectedContributor ? selectedContributor.name : '',
        },
        showSecondDropdown: true,
      },
      () => {
        console.log('Updated state:', this.state)
      }
    )
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value
    this.setState({ selectedOption })
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showInputField: !prevState.showInputField,
      showSecondDropdown: !prevState.showInputField, // Ensure the button is shown when the checkbox is checked
    }))
  }

  imageHandler() {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = () => {
      const file = input.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const imageUrl = e.target.result
        const editor = this.quill.getEditor()
        const range = editor.getSelection(true) // Get current selection
        // Insert a line break before the image to create space
        editor.insertText(range.index, '\n', Quill.sources.USER)
        // Insert the image
        const customImageHTML = ` <div style="max-width: 100%; overflow: hidden; margin: 20px 0; text-align: center;">
          <img src="${imageUrl}" style="max-width: 100%; height: auto; display: block;" />
        </div>`
        editor.clipboard.dangerouslyPasteHTML(range.index + 1, customImageHTML)
        // Insert a line break after the image to ensure space after it
        editor.insertText(range.index + 2, '\n', Quill.sources.USER)
        // Move the cursor to a new line after the image
        const newPosition = range.index + 3
        editor.setSelection(newPosition, Quill.sources.SILENT)
        // Insert an empty paragraph to set alignment properly
        editor.insertText(newPosition, '\n', Quill.sources.USER)
        // Reset text alignment to left (or the default alignment)
        editor.setSelection(newPosition + 1, Quill.sources.SILENT)
        editor.format('align', 'left', Quill.sources.USER)
      }

      reader.readAsDataURL(file)
    }
  }

  toggleEdit() {
    this.setState(
      (prevState) => ({
        isEditing: !prevState.isEditing,
      }),
      () => {
        if (this.state.isEditing && this.h2Ref.current) {
          //this.restoreCursorPosition();
          // this.h2Ref.current.focus();
        }
      }
    )
  }

  handleInputChange(event) {
    this.saveCursorPosition()
    this.setState({
      content: event.target.value,
    })
  }
  handleBlur() {
    this.setState({ isEditing: false })
  }

  saveCursorPosition() {
    const selection = window.getSelection()
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const preSelectionRange = range.cloneRange()
      preSelectionRange.selectNodeContents(this.h2Ref.current)
      preSelectionRange.setEnd(range.startContainer, range.startOffset)
      this.cursorPosition = preSelectionRange.toString().length
    }
  }

  render() {
    const {
      showInputField,
      isEditing,
      content,
      selectedQuestionData,
      selectedOption,
      showSecondDropdown,
    } = this.state

    const modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ align: [] }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: this.imageHandler,
        },
      },

      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
        displaySize: true,
      },
    }

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400, 800],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onUpdate}>
              <div className="editBoxDesign">
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  {isEditing ? (
                    <input
                      ref={this.h2Ref}
                      type="text"
                      value={content}
                      onChange={this.handleInputChange}
                      style={{
                        width: '80%',
                        textAlign: 'center',
                        font: 'normal normal 300 2.2rem/1.4 Martel',
                        color: '#000',
                      }}
                      onBlur={this.toggleEdit}
                    />
                  ) : (
                    <h2
                      className="mt-5 mb-3"
                      style={{
                        width: '80%',
                        textAlign: 'center',
                        font: 'normal normal 300 2.2rem/1.4 Martel',
                        color: '#000',
                      }}
                      onClick={this.toggleEdit}
                    >
                      {content}
                    </h2>
                  )}
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      textAlign: 'center',
                      font: 'normal normal 400 .65rem/1 Fira Sans',
                      textTransform: 'uppercase',
                      letterSpacing: '.08rem',
                      color: '#8A1A75',
                    }}
                  >
                    BY {selectedQuestionData.authorName} ON{' '}
                    {selectedQuestionData.date}
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: 'block',
                      content: '',
                      height: '2px',
                      width: '20%',
                      background: '#7f2b72',
                    }}
                  ></div>
                  <Link
                    to="/my-stories/view-question/"
                    className="mt-3"
                    style={{
                      textAlign: 'center',
                      font: 'normal normal 800 0.75rem/1 Fira Sans',
                      textTransform: 'uppercase',
                      letterSpacing: '.08rem',
                      color: '#7f2b72',
                    }}
                  >
                    ALL QUESTIONS
                  </Link>
                  <br></br>
                </Row>
                <Row className="mt-3 mb-5">
                  <Col md="12">
                    <div
                      style={{
                        font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        color: '#000',
                        textAlign: 'center',
                        position: 'relative',
                        width: '98%',
                      }}
                    >
                      {selectedQuestionData.description}
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginBottom: '5%' }}>
                  <Col md="12">
                    <ReactQuill
                      style={{ height: '400px' }}
                      ref={(el) => {
                        this.quill = el
                      }}
                      value={this.state.text}
                      onChange={this.handleChange}
                      theme="snow"
                      modules={modules}
                    />
                  </Col>
                </Row>
              </div>
              <Row
                style={{ display: 'flex', justifyContent: 'center' }}
                className="mt-5"
              >
                <span
                  style={{
                    content: '',
                    height: '2px',
                    width: '20%',
                    background: '#7f2b72',
                  }}
                ></span>
              </Row>
              <Row className="mt-5 mb-3">
                <Col md="12">
                  <div
                    style={{
                      textAlign: 'center',
                      color: '#43107E',
                      letterSpacing: '.122rem',
                      font: 'normal normal 400 0.75rem/1 Fira Sans',
                    }}
                  >
                    STATUS
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      textAlign: 'center',
                      font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                    }}
                  >
                    <label
                      style={{
                        background: '#f8eaf4',
                        padding: '2px 15px',
                        borderRadius: '18px',
                      }}
                    >
                      <input
                        type="radio"
                        value="Not Started"
                        checked={selectedOption === 'Not Started'}
                        onChange={this.handleOptionChange}
                      />
                      &nbsp;Not started
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label
                      style={{
                        background: '#f8eaf4',
                        padding: '2px 15px',
                        borderRadius: '18px',
                      }}
                    >
                      <input
                        type="radio"
                        value="Ongoing"
                        checked={selectedOption === 'Ongoing'}
                        onChange={this.handleOptionChange}
                      />
                      &nbsp;Ongoing
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label
                      style={{
                        background: '#f8eaf4',
                        padding: '2px 15px',
                        borderRadius: '18px',
                      }}
                    >
                      <input
                        type="radio"
                        value="Complete"
                        checked={selectedOption === 'Complete'}
                        onChange={this.handleOptionChange}
                      />
                      &nbsp;Complete
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 pb-3" style={{ background: '#F7EBF4' }}>
                <Col md="2"></Col>
                {showInputField === false ? (
                  <Col md="8">
                    <div
                      className="mt-5"
                      style={{
                        color: '#8A1A75',
                        font: 'normal normal 400 .65rem/1 Fira Sans',
                        letterSpacing: '.08rem',
                      }}
                    >
                      CONTRIBUTORS
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        fontWeight: '400',
                        fontFamily: 'Martel',
                        fontSize: '.85rem',
                      }}
                    >
                      Assign this question to a contributor
                    </div>
                    <div
                      style={{
                        font: 'normal normal normal 12px/17px Fira Sans',
                      }}
                    >
                      <Link to="/contributors/" style={{ fontWeight: 'bold' }}>
                        Contributors page
                      </Link>
                      to remove a contributor
                    </div>
                    <Input
                      type="select"
                      id="contributor"
                      name="contributor"
                      style={{
                        backgroundColor: '#fff',
                        color: '#43107E',
                        border: '0',
                        padding: '12px 12px 12px 30px',
                        borderRadius: '50px',
                        margin: '10px 0 0',
                        font: 'normal normal normal 15px/17px Fira Sans',
                        fontWeight: '400',
                      }}
                      onChange={this.handleContributorChange}
                      value={this.state.selectedContributor || ''}
                    >
                      <option value="">Please select a contributor</option>
                      {this.state.contributor &&
                      this.state.contributor.length > 0 ? (
                        this.state.contributor.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>No contributors available</option>
                      )}
                    </Input>
                    <br></br>
                    <Label
                      className="mt-3"
                      style={{
                        font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        color: '#404040',
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={this.handleCheckboxChange}
                      />
                      &nbsp;Add a new contributor
                    </Label>
                    <br></br>
                    {showSecondDropdown && (
                      <button
                        type="button"
                        className="custom-button"
                        onClick={this.onSendInvite}
                      >
                        {SendAnInviteToThisContributor}
                      </button>
                    )}
                  </Col>
                ) : (
                  <Col md="8">
                    <div
                      className="mt-5"
                      style={{
                        color: '#8A1A75',
                        font: 'normal normal 400 .65rem/1 Fira Sans',
                        letterSpacing: '.08rem',
                      }}
                    >
                      CONTRIBUTORS
                    </div>
                    <Label
                      className="mt-3"
                      style={{
                        font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        color: '#404040',
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={this.handleCheckboxChange}
                        checked={this.state.showInputField}
                      />
                      &nbsp;Add a new contributor
                    </Label>
                    <br></br>
                    <Label
                      style={{
                        fontWeight: '400',
                        fontFamily: 'Martel',
                        fontSize: '.85rem',
                      }}
                      className="mt-3"
                    >
                      Contributor Name
                    </Label>
                    <Input
                      type="text"
                      name="contributor_name"
                      id="contributor_name"
                      onChange={this.handleInput}
                      value={this.state.contributor_name}
                    />
                    <Label
                      style={{
                        fontWeight: '400',
                        fontFamily: 'Martel',
                        fontSize: '.85rem',
                      }}
                      className="mt-3"
                    >
                      Contributor Email
                    </Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      onChange={this.handleInput}
                      value={this.state.email}
                    />
                    {this.state.showSecondDropdown && (
                      <button
                        type="button"
                        className="custom-button"
                        onClick={this.onInsertContributor}
                        style={{ marginTop: '1%' }}
                      >
                        {SendAnInviteToThisContributor}
                      </button>
                    )}
                  </Col>
                )}
                <Col md="2"></Col>
              </Row>
              <Row className="mt-5 mb-5">
                <Col md="5"></Col>
                <Col md="3">
                  <button
                    type="button"
                    className="delete-button"
                    data-test-id={selectedQuestionData.id}
                    onClick={this.onDeleteClick}
                  >
                    Delete question
                  </button>
                </Col>
              </Row>
              <Row>
                <div
                  style={{
                    position: 'fixed',
                    bottom: '0',
                    zIndex: '10000',
                    left: '0',
                    right: '0',
                    background: 'rgba(255,255,255,.7)',
                    padding: '20px 0px 20px 20px',
                    borderTop: '1px solid #e8d2e4',
                    textAlign: 'center',
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      background: 'white',
                      letterSpacing: '0.15rem',
                      border: '2px solid #8A1A75',
                      fontWeight: '300',
                      color: '#8a1975',
                      borderRadius: '28px',
                      padding: '0 30px',
                      textTransform: 'uppercase',
                      minHeight: '50px',
                      fontSize: '1.1rem',
                      fontFamily: 'Fira Sans',
                    }}
                  >
                    {Save}
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  listQuestionDetails: myStoryOperations.listQuestionDetails,
  updateQuestion: myStoryOperations.updateQuestion,
  addContributor: myStoryOperations.addContributor,
  SendAnInviteToThisContributor:
    myStoryOperations.SendAnInviteToThisContributor,
  contributorNameList: myStoryOperations.contributorNameList,
  deleteQuestion: myStoryOperations.deleteQuestion,
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
