import React, { Fragment } from 'react';
import { Container, CardBody, Col, Form, Row, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { myStoryOperations } from '../../state/ducks/story';
import GoogleFontLoader from 'react-google-font-loader';
import { toast } from 'react-toastify';
import { UpdateContributor } from '../../Constant';

class Contributors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allContributorData: [],
      selectedContributorData: {},
      removedQuestions: [],
      selectedStoryData: [],
      activeQuestionId: null, // new state to track the active question's id
    }
  }

  componentDidMount = async () => {
    const{loggedInUserId} = this.props;
    if(!loggedInUserId) {
      console.log("No user ID found");
      return;
    }
    const response = await this.props.listMyStory({ "id": loggedInUserId });
    this.setState({ selectedStoryData: response.payload });
    try {
      const response = await this.props.listContributor();
      this.setState({ allContributorData: response.payload });
    } catch (error) {
      console.log(error);
    }
  }

  handleEditClick = (e) => {
    const questionId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedQuestionId', questionId)
    window.open('/my-stories/view-question/edit/', '_self')
  }

  handleViewClick = (e) => {
    const questionId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedQuestionId', questionId)
    window.open('/view-question/', '_self')
  }

  handleCheckboxChange = (contributor) => {
    this.setState((prevState) => ({
      allContributorData: prevState.allContributorData.map((item) => {
        if (item.id === contributor.id) {
          return { ...item, showInputField: !item.showInputField }
        }
        return item
      }),
      selectedContributorData: contributor,
    }))
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      selectedContributorData: {
        ...prevState.selectedContributorData,
        [name]: value,
      },
    }));
  };

  onDeleteClick = async (e) => {
    e.preventDefault();
    const confirmed = window.confirm("Are you sure, you want to delete it?");
    if (!confirmed) return;

    try {
      let contributorId = e.target.getAttribute('data-test-id');
      const response = await this.props.deleteContributor({ id: contributorId })
      toast.success(response.message, { autoClose: 10000 })
      this.setState((prevState) => ({
        allContributorData: prevState.allContributorData.filter(
          (item) => item.id !== contributorId
        ),
      }));
      window.open('/contributors/', '_self');
    } catch (err) {
      console.log(err);
    }
  }

  onRemoveClick = async (contributorId, questionId) => {
    const confirmed = window.confirm(
      'Are you sure you want to remove this question from the contributors list?'
    )
    if (!confirmed) return
    try {
      const response = await this.props.removeQuestionFromContributor({
        contributorId,
        questionId,
      })
      toast.success(response.message, { autoClose: 10000 })

      // Add the removed question ID to the state
      this.setState(prevState => ({
        removedQuestions: [...prevState.removedQuestions, questionId]
      }));

      // Remove the question from the state
      this.setState((prevState) => ({
        allContributorData: prevState.allContributorData.map((contributor) => {
          if (contributor.id === contributorId) {
            return {
              ...contributor,
              questions: contributor.questions.filter(
                (q) => q.id !== questionId
              ),
            }
          }
          return contributor
        }),
      }))
    } catch (err) {
      console.log(err)
    }
  }

  onMarkClick = async (contributorId, questionId) => {
    try {
      const response = await this.props.markAsCompleteFromContributor({
        contributorId,
        questionId,
      })
      toast.success(response.message, { autoClose: 10000 })
    } catch (err) {
      console.log(err)
    }
  }
  onUpdate = async (e) => {
    e.preventDefault()
    try {
      const { selectedContributorData } = this.state
      let updateResponse = await this.props.updateContributor({
        id: selectedContributorData.id,
        name: selectedContributorData.name,
        email: selectedContributorData.email,
      })
      toast.success(updateResponse.message, { autoClose: 10000 })
      this.setState((prevState) => ({
        allContributorData: prevState.allContributorData.map((item) =>
          item.id === selectedContributorData.id
            ? selectedContributorData
            : item
        ),
        selectedContributorData: {},
      }))
    } catch (err) {
      toast.error(err.updateResponse?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }
  toggleDropdown = (questionId) => {
    this.setState((prevState) => ({
      activeQuestionId:
        prevState.activeQuestionId === questionId ? null : questionId,
    }))
  }

  handleCancelEdit = (contributorId) => {
    this.setState((prevState) => ({
      allContributorData: prevState.allContributorData.map((item) => {
        if (item.id === contributorId) {
          return { ...item, showInputField: false }
        }
        return item
      }),
      selectedContributorData: {},
    }))
  }

  render() {
    const {
      allContributorData,
      selectedContributorData,
      activeQuestionId,
      selectedStoryData,
    } = this.state
    const ulStyle = {
      display: 'flex',
      padding: '2px 0px 10px 0px',
      fontSize: '12px',
      fontWeight: '400',
    }

    const liStyle = { padding: '0 10px' }

    const dropdownContentStyle = {
      display: 'none',
    }

    const showDropdownContentStyle = {
      ...dropdownContentStyle,
      display: 'block',
    }

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 400, 700],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form
              onSubmit={this.onUpdate}
              style={{ maxWidth: '930px', margin: '0 auto' }}
            >
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 400 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  Contributors
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
              </Row>
              <Row className="mt-3">
                <Col md="12">
                  <div className="mt-3" style={{ textAlign: 'center' }}>
                    <span
                      style={{
                        font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        color: '#404040',
                        padding: '0px 0px 16px',
                      }}
                    >
                      Details get hazy from time to time. Collaborate with
                      family or friends to fill in the gaps or add their
                      perspective to a shared experience.{' '}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <Col md="12">
                  <div style={{ textAlign: 'center' }}>
                    <span
                      style={{
                        font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        color: '#404040',
                      }}
                    >
                      Below are the people you have invited to contribute to
                      your stories. Once they have submitted their contribution
                      you can choose to edit/delete or incorporate it into your
                      final story.{' '}
                    </span>
                  </div>
                </Col>
              </Row>
              {allContributorData.map((contributor) => (
                <div
                  key={contributor.id}
                  style={{ marginBottom: '40px', marginTop: '40px' }}
                >
                  <Row>
                    <Col md="12">
                      <div
                        style={{
                          background: '#eee',
                          padding: '1.5rem 2rem',
                          font: 'normal normal 400 .75rem/1 Fira Sans',
                        }}
                      >
                        {!contributor.showInputField ? (
                          <>
                            <div
                              className="mb-1"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: '#8A1A75',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '17.6px',
                                  color: '#000000',
                                  textTransform: 'capitalize',
                                  font: 'normal normal 300 1.1rem / 1.1 Martel',
                                }}
                              >
                                {contributor.name}
                              </span>
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  this.handleCheckboxChange(contributor)
                                }
                              >
                                EDIT CONTRIBUTOR
                              </span>
                            </div>
                            <div
                              style={{
                                fontSize: '1rem',
                                color: '#404040',
                                fontFamily: 'Fira Sans',
                                fontWeight: '300',
                              }}
                            >
                              {contributor.email}
                            </div>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col md="8">
                                <Input
                                  type="text"
                                  className="mb-1"
                                  name="name"
                                  id="name"
                                  value={selectedContributorData.name}
                                  onChange={this.handleInputChange}
                                />
                                <Input
                                  type="text"
                                  name="email"
                                  id="email"
                                  value={selectedContributorData.email}
                                  onChange={this.handleInputChange}
                                />
                              </Col>
                              <Col
                                style={{ textAlign: 'right', color: '#8A1A75' }}
                              >
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    this.handleCheckboxChange(contributor)
                                  }
                                >
                                  EDIT CONTRIBUTOR
                                </span>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <button
                                  type="reset"
                                  style={{
                                    font: 'normal normal 400 .75rem/1 Fira Sans',
                                    textTransform: 'uppercase',
                                    color: '#8A1A75',
                                    letterSpacing: '.08rem',
                                    border: 'none',
                                    background: 'none',
                                  }}
                                  onClick={() =>
                                    this.handleCancelEdit(contributor.id)
                                  }
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btnDeleteContributor"
                                  type="button"
                                  data-test-id={contributor.id}
                                  onClick={this.onDeleteClick}
                                  style={{
                                    background: '#AD0000',
                                    border: '2px solid #AD0000',
                                    fontWeight: '300',
                                    color: '#fff',
                                    borderRadius: '30px',
                                    padding: '8px 16px',
                                    textTransform: 'uppercase',
                                    letterSpacing: '.122rem',
                                  }}
                                >
                                  Delete Contributor
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                        {contributor.questions.map((question) => (
                          <div
                            className="contributorIteam"
                            key={question.id}
                            style={{
                              marginTop: '20px',
                              background: '#fff',
                              border: '0',
                              borderRadius: '9px',
                              position: 'relative',
                              fontWeight: '400',
                              display: 'flex',
                            }}
                          >
                            <div
                              className="contributorIteamQ"
                              style={{
                                background: '#8A1A75',
                                borderRadius: '10px 0 0 10px',
                                width: '25px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#fff',
                              }}
                            >
                              <span>Q</span>
                            </div>
                            <div
                              style={{
                                paddingLeft: '20px',
                                flexGrow: 1,
                                paddingTop: '1%',
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: '400',
                                  fontSize: '17.6px',
                                  color: '#8A1A75',
                                  fontFamily: 'Fira Sans',
                                  display: 'block',
                                  marginBottom: '1%',
                                }}
                              >
                                {question.question}
                              </span>
                              <span
                                style={{
                                  fontSize: '13.6px',
                                  display: 'block',
                                  color: '#212529',
                                  fontWeight: '400',
                                  paddingTop: '2px',
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: '300',
                                    fontFamily: 'Fira Sans',
                                  }}
                                >
                                  Story:
                                </span>{' '}
                                {selectedStoryData.title}
                              </span>
                              {/* ))} */}
                              <span
                                style={{
                                  fontSize: '13.6px',
                                  display: 'block',
                                  color: '#212529',
                                  fontWeight: '400',
                                  paddingTop: '2px',
                                  paddingBottom: '10px',
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: '300',
                                    fontFamily: 'Fira Sans',
                                    paddingBottom: '5px',
                                  }}
                                >
                                  Last edited:
                                </span>{' '}
                                {question.last_edited}
                              </span>
                            </div>
                            <div
                              className="contributorIteamEditOption"
                              style={{
                                background: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '0 10px 10px 0',
                              }}
                            >
                              <ul style={ulStyle}>
                                <li
                                  style={liStyle}
                                  data-test-id={question.id}
                                  onClick={this.handleViewClick}
                                >
                                  <Link to="#">VIEW</Link>
                                </li>
                                <span
                                  className="contributorIteamLeftIcon"
                                  style={{
                                    background: '#FEC1EB',
                                    color: 'transparent',
                                    display: 'inline-block',
                                    position: 'relative',
                                    verticalAlign: 'bottom',
                                    height: '15px',
                                    width: '2px',
                                  }}
                                ></span>
                                <li
                                  className="flexdownarrow"
                                  onClick={() =>
                                    this.toggleDropdown(question.id)
                                  }
                                >
                                  OPTIONS{' '}
                                  <span
                                    style={{
                                      color: '#FEC1EB',
                                      fontWeight: '600',
                                    }}
                                  >
                                    ∨
                                  </span>
                                  <div
                                    className="contributorOption"
                                    style={
                                      activeQuestionId === question.id
                                        ? showDropdownContentStyle
                                        : dropdownContentStyle
                                    }
                                  >
                                    <div
                                      onClick={() =>
                                        this.onMarkClick(
                                          contributor.id,
                                          question.id
                                        )
                                      }
                                    >
                                      Mark as complete
                                    </div>
                                    <div
                                      onClick={() =>
                                        this.onRemoveClick(
                                          contributor.id,
                                          question.id
                                        )
                                      }
                                    >
                                      Remove question from contributor
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  listContributor: myStoryOperations.listContributor,
  deleteContributor: myStoryOperations.deleteContributor,
  updateContributor: myStoryOperations.updateContributor,
  listContributorDetails: myStoryOperations.listContributorDetails,
  removeQuestionFromContributor:
    myStoryOperations.removeQuestionFromContributor,
  markAsCompleteFromContributor:
    myStoryOperations.markAsCompleteFromContributor,
  listMyStory: myStoryOperations.listMyStory,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contributors)
