import React, { Fragment } from 'react'
import { Container, Card, Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { myStoryOperations } from '../../state/ducks/story'

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />
}
class PrintOrderConfirmedDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentIntentId: '',
    }
  }

  componentDidMount = async () => {
    let paymentRetrieveResponse = await this.props.PaymentSuccess(
      this.props.params.sessionId
    )
    console.log(paymentRetrieveResponse)
  }

  render() {
    return (
      <Fragment>
        <div className="page-title"></div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card style={{ boxShadow: 'none' }}>
                <h1
                  style={{
                    textAlign: 'center',
                    marginTop: '2.3%',
                    font: 'normal normal 300 2.5rem/1.4 Martel',
                  }}
                >
                  Print order confirmed
                </h1>
                <span
                  style={{
                    borderBottom: '2px solid #FEC1EB',
                    height: '11px',
                    width: '102px',
                    margin: '10px auto',
                  }}
                ></span>
                <Card></Card>
              </Card>
            </Col>
          </Row>
          <div>
            <div
              style={{
                background: '#eeeeee',
                padding: '80px 0',
                width: '100vw',
                position: 'relative',
                left: 'calc(-1*(100vw - 100%) / 2)',
              }}
            >
              <Container></Container>

              <div>
                <row></row>
                <div>
                  <col></col>
                  <div>
                    <col mb-4 pt-0></col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
    )
  }
}

const PrintOrderConfirmed = withParams(PrintOrderConfirmedDetail)

function mapStateToProps(state) {
  return state
}

const mapDispatchToProps = {
  PaymentSuccess: myStoryOperations.PaymentSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintOrderConfirmed)
