import React from 'react'
import { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Loader from '../Layout/Loader'
import { authRoutes } from './AuthRoutes'
import LayoutRoutes from '../Route/LayoutRoutes'
import PrivateRoute from './PrivateRoute'
import Signin from '../Pages/Auth/Signin/Signin'
// setup fake backend

const Routers = () => {
  const token = useState(localStorage.getItem('token'))[0]
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    let abortController = new AbortController()
    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')))
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={'/'} element={<PrivateRoute />}>
            {(token || authenticated) && (
              <>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}`}
                  element={
                    <Navigate to={`${process.env.PUBLIC_URL}/my-stories/`} />
                  }
                />
                <Route
                  exact
                  path={`/`}
                  element={
                    <Navigate to={`${process.env.PUBLIC_URL}/my-stories/`} />
                  }
                />
              </>
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Signin/`}
            element={<Signin />}
          />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routers
