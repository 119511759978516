import React, { Fragment } from 'react'
import { Container, CardBody, Col, Form, Row } from 'reactstrap'
import { connect } from 'react-redux'
import GoogleFontLoader from 'react-google-font-loader'
import '../Button.css'
import { myStoryOperations } from '../../state/ducks/story'
import '../ViewThisStory.css' // Include this for custom CSS
import { toast } from 'react-toastify'

class ViewThisStory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuestionData: null,
      text: '',
      content: '',
      currentQuestionIndex: 0,
      allQuestionData: [],
      isSidebarOpen: false, // New state for sidebar visibility
      isInviteFormOpen: false,
      inviteSentMessage: '',
      inviteeName: '',
      inviteeEmail: '',
      inviteMessage: '',
      selectedStoryData: {},
    }
    // Bind your methods here if not using arrow functions in class properties
    this.handleSendInvite = this.handleSendInvite.bind(this)
  }

  async componentDidMount() {
    const { loggedInUserId } = this.props
    if (!loggedInUserId) {
      console.log('No user ID found')
      return
    }
    const response = await this.props.listMyStory({ id: loggedInUserId })
    this.setState({ selectedStoryData: response.payload })
    try {
      const questionId = JSON.parse(localStorage.getItem('selectedQuestionId'))
      const allQuestionData = JSON.parse(
        localStorage.getItem('allQuestionData')
      )
      if (questionId && allQuestionData) {
        const currentIndex = allQuestionData.findIndex(
          (q) => q.id === questionId
        )
        await this.loadQuestionData(allQuestionData, currentIndex)
      }
    } catch (error) {
      console.log(error)
    }
  }

  loadQuestionData = async (allQuestionData, index) => {
    const questionId = allQuestionData[index].id
    const response = await this.props.listQuestionDetails({ id: questionId })

    if (response.payload) {
      this.setState({
        selectedQuestionData: response.payload,
        text: response.payload.answer,
        content: response.payload.question,
        currentQuestionIndex: index,
        allQuestionData: allQuestionData,
      })
    }
  }

  handleNextQuestion = () => {
    const { currentQuestionIndex, allQuestionData } = this.state
    const nextIndex = currentQuestionIndex + 1
    if (nextIndex < allQuestionData.length) {
      this.loadQuestionData(allQuestionData, nextIndex)
    }
  }

  handlePreviousQuestion = () => {
    const { currentQuestionIndex, allQuestionData } = this.state
    const prevIndex = currentQuestionIndex - 1
    if (prevIndex >= 0) {
      this.loadQuestionData(allQuestionData, prevIndex)
    }
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({ isSidebarOpen: !prevState.isSidebarOpen }))
  }

  toggleInviteForm = () => {
    this.setState((prevState) => ({
      isInviteFormOpen: !prevState.isInviteFormOpen,
      inviteSentMessage: '',
    }))
  }

  handleQuestionClick = (index) => {
    this.loadQuestionData(this.state.allQuestionData, index)
    this.setState({ isSidebarOpen: false })
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSendInvite = async (e) => {
    e.preventDefault()
    const { inviteeName, inviteeEmail, inviteMessage } = this.state
    const inviteData = {
      name: inviteeName,
      email: inviteeEmail,
      message: inviteMessage,
    }
    try {
      await this.props.SendAnInviteToStory(inviteData)
      this.setState({
        inviteSentMessage: 'An invite has been sent.',
        isInviteFormOpen: false,
      })
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 10000 })
      console.error('Error sending invite:', error)
    }
  }

  render() {
    const {
      content,
      text,
      currentQuestionIndex,
      allQuestionData,
      isSidebarOpen,
      inviteSentMessage,
      isInviteFormOpen,
      inviteeName,
      inviteeEmail,
      selectedStoryData,
      message: inviteMessage,
    } = this.state
    const nextQuestion =
      currentQuestionIndex + 1 < allQuestionData.length
        ? allQuestionData[currentQuestionIndex + 1]
        : null
    const prevQuestion =
      currentQuestionIndex - 1 >= 0
        ? allQuestionData[currentQuestionIndex - 1]
        : null
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [400, 500, 300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 500, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form>
              <Row>
                <div className="mt-5" style={{ textAlign: 'center' }}>
                  <button
                    style={{ margin: '0 auto' }}
                    type="button"
                    className="custom-button"
                    onClick={this.toggleSidebar}
                  >
                    {'Chapters '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="feather feather-chevron-right"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <polyline points="9 18 15 12 9 6" />
                    </svg>
                  </button>
                </div>
              </Row>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 300 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  {selectedStoryData.title}
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
              </Row>
              <Row className="mt-3">
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '17px',
                    fontWeight: '300',
                    fontFamily: 'Fira Sans',
                  }}
                >
                  {this.props.loggedInUserName}
                </div>
              </Row>
              <Row
                className="mt-3"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Col xl="5">
                  <h3
                    style={{
                      font: 'normal normal 300 1.6rem/1.4 Martel',
                      textAlign: 'center',
                      wordWrap: 'break-word',
                    }}
                  >
                    {content}
                  </h3>
                  <div
                    className="mt-4"
                    style={{ font: 'normal normal 300 1.3rem/1.4 Fira Sans' }}
                    dangerouslySetInnerHTML={{ __html: text }}
                  ></div>
                </Col>
              </Row>
              <Row
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div
                  style={{ height: '2px', width: '15%', background: '#7f2b72' }}
                ></div>
              </Row>
              <Row
                className="mt-5"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '18px',
                  fontWeight: '700',
                  fontFamily: 'Fira Sans',
                }}
              >
                {nextQuestion && (
                  <Col
                    xl="5"
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                    onClick={this.handleNextQuestion}
                  >
                    <div
                      style={{
                        border: '1px solid #7f2772',
                        padding: '10px 17px',
                        borderRadius: '19px',
                        display: 'inline-block',
                      }}
                    >
                      <strong style={{ color: '#000' }}>Next:&nbsp;</strong>
                      <span style={{ color: '#000' }}>
                        {nextQuestion.question}
                      </span>
                    </div>
                  </Col>
                )}
              </Row>

              <Row
                className="mt-1"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '18px',
                  fontWeight: '700',
                  fontFamily: 'Fira Sans',
                }}
              >
                {prevQuestion && (
                  <Col
                    xl="5"
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                    onClick={this.handlePreviousQuestion}
                  >
                    <div
                      style={{
                        border: '1px solid #7f2772',
                        padding: '10px 17px',
                        borderRadius: '19px',
                        display: 'inline-block',
                      }}
                    >
                      <strong style={{ color: '#000' }}>Previous:&nbsp;</strong>
                      <span style={{ color: '#000' }}>
                        {prevQuestion.question}
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="mt-1 mb-5"></Row>
              <Row className="mt-1 mb-5"></Row>
            </Form>
          </CardBody>
        </Container>
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className="sidebarInner">
            <h2>Chapters</h2>
            <ol>
              {allQuestionData.map((question, index) => (
                <li
                  key={question.id}
                  onClick={() => this.handleQuestionClick(index)}
                >
                  {question.question}
                </li>
              ))}
            </ol>
            <button
              style={{
                background: '#fff',
                border: 'none',
                padding: '21px 20px',
                display: 'flex',
                justifyContent: 'center',
                letterSpacing: '0.115rem',
                font: 'normal normal normal 0.95rem / 1.2 Fira Sans',
              }}
              onClick={this.toggleInviteForm}
            >
              {' '}
              {'SEND AN INVITE TO THIS STORY'}&nbsp;{' '}
              <span style={{ color: '#8A1A75' }}>{'>'}</span>
            </button>
            {isInviteFormOpen && (
              <div className="invite-form">
                <h4>Send invite to view this story</h4>
                <p>
                  Send an invite which contains a unique url to view this book
                </p>
                <form onSubmit={this.handleSendInvite}>
                  <div style={{ marginBottom: '10px' }}>
                    <label>Invitee name</label>
                    <input
                      type="name"
                      name="inviteeName"
                      value={inviteeName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <label>Invitee email</label>
                    <input
                      type="email"
                      name="inviteeEmail"
                      value={inviteeEmail}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <label>Message</label>
                    <textarea
                      style={{ height: '288px' }}
                      name="inviteMessage"
                      value={inviteMessage}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <button type="submit">Send Invite</button>
                </form>
              </div>
            )}
            {inviteSentMessage && (
              <div className="invite-sent-message">
                <h3>Send invite to view this story</h3>
                <span>{inviteSentMessage}</span>
              </div>
            )}
          </div>
        </div>
        <div
          className={`sidebar-overlay ${isSidebarOpen ? 'open' : ''}`}
          onClick={this.toggleSidebar}
        ></div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserId: state.auth.session.user.id,
    loggedInUserName: state.auth.session.user.user_name,
  }
}

const mapDispatchToProps = {
  listQuestionDetails: myStoryOperations.listQuestionDetails,
  SendAnInviteToStory: myStoryOperations.SendAnInviteToStory,
  listMyStory: myStoryOperations.listMyStory,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewThisStory)
