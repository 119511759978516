import * as types from './types'
import * as API_URL from '../../../env'

export const updateMyStory = (myStory) => ({
  type: types.UPDATE_MY_STORY,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/story/update-story',
    method: 'PUT',
    body: myStory,
  },
})

export const listMyStory = (payload) => ({
  type: types.FETCH_MY_STORY_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/story/get-story-details',
    method: 'POST',
    body: payload,
  },
})

export const listRenewalStory = (payload) => ({
  type: types.FETCH_RENEWAL_STORY_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/story/get-renewal-story-details',
    method: 'GET',
    body: payload,
  },
})

export const getMyStory = (payload) => ({
  type: types.FETCH_MY_STORY_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/story/get-story-details-by-id',
    method: 'POST',
    body: payload,
  },
})

export const listQuestionWithStoryId = (payload) => ({
  type: types.FETCH_QUESTION_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/list-question',
    method: 'POST',
    body: payload,
  },
})

export const listQuestion = (payload) => ({
  type: types.FETCH_QUESTION_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/list-question',
    method: 'POST',
    body: payload,
  },
})

export const listQuestionDetails = (payload) => ({
  type: types.FETCH_QUESTION_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/user/question/get-question-details',
    method: 'POST',
    body: payload,
  },
})

export const listQuestionDetailsWithImageToBase64 = (payload) => ({
  type: types.FETCH_QUESTION_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/user/question/get-question-details-with-image-to-base64',
    method: 'POST',
    body: payload,
  },
})

export const listContributorQuestionDetails = (payload) => ({
  type: types.FETCH_QUESTION_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/user/question/get-contributor-question-details',
    method: 'POST',
    body: payload,
  },
})
export const addQuestion = (payload) => ({
  type: types.INSERT_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/add-question',
    method: 'POST',
    body: payload,
  },
})

export const justWrite = (payload) => ({
  type: types.INSERT_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/just-write',
    method: 'POST',
    body: payload,
  },
})

export const updateQuestion = (question) => ({
  type: types.UPDATE_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/update-question',
    method: 'PUT',
    body: question,
  },
})

export const updateContributorQuestion = (question) => ({
  type: types.UPDATE_CONTRIBUTOR_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/user/question/update-contributor-question',
    method: 'PUT',
    body: question,
  },
})

export const deleteQuestion = (payload) => ({
  type: types.DELETE_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/delete-question   ',
    method: 'DELETE',
    body: payload,
  },
})

export const listCategory = (payload) => ({
  type: types.FETCH_CATEGORY_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/category/list-category',
    method: 'POST',
    body: payload,
  },
})
export const listQuestionByCategory = (payload) => ({
  type: types.FETCH_QUESTION_LIST_BY_CATEGORY_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/user/question/get-question-details-by-category',
    method: 'POST',
    body: payload,
  },
})

export const listQuestionByLanguage = (payload) => ({
  type: types.FETCH_QUESTION_LIST_BY_LANGUAGE,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/category/get-category-details-by-language',
    method: 'POST',
    body: payload,
  },
})

export const listContributor = () => ({
  type: types.FETCH_CONTRIBUTOR_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/contributor/list-contributor',
    method: 'GET',
    // body: payload
  },
})

export const contributorNameList = () => ({
  type: types.FETCH_CONTRIBUTOR_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/contributor/contributor-name-list',
    method: 'GET',
    // body: payload
  },
})

export const listContributorDetails = (payload) => ({
  type: types.FETCH_QUESTION_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/contributor/get-contributor-details',
    method: 'POST',
    body: payload,
  },
})

export const deleteContributor = (payload) => ({
  type: types.DELETE_CONTRIBUTOR,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/contributor/delete-contributor',
    method: 'DELETE',
    body: payload,
  },
})

export const updateContributor = (contributor) => ({
  type: types.UPDATE_CONTRIBUTOR,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/contributor/update-contributor',
    method: 'PUT',
    body: contributor,
  },
})

export const removeQuestionFromContributor = (contributor) => ({
  type: types.UPDATE_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/contributor/delete-question-from-contributor',
    method: 'DELETE',
    body: contributor,
  },
})

export const markAsCompleteFromContributor = (contributor) => ({
  type: types.UPDATE_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/contributor/update-question-status',
    method: 'POST',
    body: contributor,
  },
})

export const RenewSubscription = (contributor) => ({
  type: types.INSERT_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/renewal/renewal-subscription',
    method: 'POST',
    body: contributor,
  },
})

export const getSubscriptionPricing = (payload) => ({
  type: types.FETCH_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/renewal/get-subscription-pricing',
    method: 'POST',
    body: payload,
  },
})

export const getDiscountCode = (payload) => ({
  type: types.FETCH_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/renewal/get-discount-code',
    method: 'POST',
    body: payload,
  },
})

export const listLanguage = (payload) => ({
  type: types.FETCH_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/language/list-language',
    method: 'POST',
    body: payload,
  },
})

export const addContributor = (payload) => ({
  type: types.INSERT_CONTRIBUTOR,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/contributor/add-contributor',
    method: 'POST',
    body: payload,
  },
})

export const SendAnInviteToThisContributor = (payload) => ({
  type: types.INSERT_CONTRIBUTOR,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/contributor/send-email-to-contributor',
    method: 'POST',
    body: payload,
  },
})

export const sendPDF = (payload) => ({
  type: types.PDF_SEND,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/send-PDF',
    method: 'POST',
    body: payload,
  },
})

export const generatePDF = (payload) => ({
  type: types.PDF_SEND,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/question/generate-PDF',
    method: 'POST',
    body: payload,
    responseType: 'arraybuffer',
  },
})

export const addOrderBook = (payload) => ({
  type: types.INSERT_ORDERBOOK,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/add-order-book',
    method: 'POST',
    body: payload,
  },
})

export const listOrderBook = (payload) => ({
  type: types.FETCH_ORDERBOOK_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/get-order-book-list',
    method: 'GET',
    body: payload,
  },
})

export const deleteOrderBook = (payload) => ({
  type: types.DELETE_ORDERBOOK,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/delete-order-book',
    method: 'DELETE',
    body: payload,
  },
})

export const fetchOrderBookById = (payload) => ({
  type: types.FETCH_ORDERBOOK_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/get-order-book-details',
    method: 'POST',
    body: payload,
  },
})

export const updateOrderBook = (payload) => ({
  type: types.UPDATE_ORDERBOOK,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/update-order-book',
    method: 'PUT',
    body: payload,
  },
})

export const addAdditionalOrderBook = (payload) => ({
  type: types.INSERT_ADDITIONAL_ORDERBOOK,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/add-additional-book',
    method: 'POST',
    body: payload,
  },
})

export const listAdditionalOrderBook = (payload) => ({
  type: types.FETCH_ADDITIONAL_ORDERBOOK_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/orderbook/get-additional-book-list',
    method: 'GET',
    body: payload,
  },
})

export const deleteAdditionalOrderBook = (payload) => ({
  type: types.DELETE_ADDITIONAL_ORDERBOOK,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/delete-additional-book',
    method: 'DELETE',
    body: payload,
  },
})

export const fetchAdditionalOrderBookById = (payload) => ({
  type: types.FETCH_ADDITIONAL_ORDERBOOK_LIST,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL +
      '/orderbook/get-additional-book-details',
    method: 'POST',
    body: payload,
  },
})

export const Payment = (payload) => ({
  type: types.PAYMENT_SUCCESSFULLY,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/payment/create-checkout-session',
    method: 'POST',
    body: payload,
  },
})

export const PaymentSuccess = (sessionId) => ({
  type: types.PAYMENT_SUCCESSFULLY_COMPLETED,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/payment/payment-success/' + sessionId,
    method: 'GET',
  },
})

export const RenewalPaymentSuccess = (sessionId) => ({
  type: types.RENEWAL_PAYMENT_SUCCESSFULLY_COMPLETED,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/renewal/payment-success/' + sessionId,
    method: 'GET',
  },
})

export const getCoverColorDetails = (payload) => ({
  type: types.FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR,
  meta: {
    async: true,
    blocking: true,
    path:
      API_URL.BACKEND_SERVICE_API_URL + '/coverColor/list-cover-color-details',
    method: 'POST',
    body: payload,
  },
})

export const updateAdditionalOrderBook = (payload) => ({
  type: types.UPDATE_ADDITIONAL_ORDERBOOK,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/orderbook/update-additional-book',
    method: 'PUT',
    body: payload,
  },
})

export const SendAnInviteToStory = (payload) => ({
  type: types.SEND_STORY_TO_INVITEE,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/invitee/send-email-to-invitee',
    method: 'POST',
    body: payload,
  },
})

export const sendQuestion = (payload) => ({
  type: types.SEND_QUESTION,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/user/send-question-to-gift-user',
    method: 'POST',
    body: payload,
  },
})

export const listDiscount = (payload) => ({
  type: types.FETCH_DISCOUNT_LIST,
  meta: {
    async: true,
    blocking: true,
    path: API_URL.BACKEND_SERVICE_API_URL + '/renewal/list-discount-code',
    method: 'GET',
    body: payload,
  },
})
