import React, { Fragment } from 'react'
import { Container, Col, Row, Label, Input } from 'reactstrap'
import html2pdf from 'html2pdf.js'
import GoogleFontLoader from 'react-google-font-loader'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import '../Button.css'
import * as API_URL from '../../env.js'

class Pdf extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: 'option1',
      selectedQuestionData: '',
      showPdfContent: false,
      email: '', // Add email to state
      message: 'Please find my question PDF attached.',
      loading: false,
      text: '',
      content: '',
    }
  }

  async componentDidMount() {
    const questionId = localStorage.getItem('selectedQuestionId')
    this.setState({
      selectedQuestionData: questionId,
    })
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value
    this.setState({ selectedOption })
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  generatePDF = () => {
    const { selectedOption, selectedQuestionData } = this.state
    if (selectedOption === 'option2') {
      this.sendPDFToEmail()
    } else {
      let fileURL = `${
        API_URL.API_URL + '/pdf/' + selectedQuestionData + '.pdf'
      }`
      const pdfWindow = window.open()
      pdfWindow.location.href = fileURL
    }
  }

  sendPDFToEmail = async () => {
    this.setState({ loading: true })
    try {
      const { selectedQuestionData, email, message } = this.state

      const payload = {
        id: selectedQuestionData,
        email,
        message,
      }
      const response = await this.props.sendPDF(payload)
      toast.success(response.message, { autoClose: 10000 })
    } catch (error) {
      console.error('Error sending invitation:', error.response.data.message)
      toast.error(error.response.data.message, {
        autoClose: 10000,
      })
    } finally {
      this.setState({ loading: false }) // Stop loading
    }
  }

  render() {
    const { selectedOption, text, showPdfContent, email, message } = this.state
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <ToastContainer />
          <Row>
            <h2
              className="mt-5"
              style={{
                textAlign: 'center',
                font: 'normal normal 300 2.2rem/1.4 Martel',
                color: '#000',
              }}
            >
              Preview your PDF or share it with
            </h2>
            <h2
              style={{
                textAlign: 'center',
                font: 'normal normal 300 2.2rem/1.4 Martel',
                color: '#000',
              }}
            >
              the email of your choice
            </h2>
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  borderBottom: '1px solid #FEC1EB',
                  height: '1px',
                  width: '102px',
                  display: 'inline-block',
                }}
              ></span>
            </div>
          </Row>
          <span
            style={{
              borderBottom: '2px solid #FEC1EB',
              height: '2px',
              width: '102px',
              margin: '20px auto',
            }}
          ></span>
          <Row>
            <Col sm="3"></Col>
            <Col sm="8">
              <div>
                <label
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Fira Sans',
                    fontWeight: '300',
                  }}
                >
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={this.handleOptionChange}
                  />
                  &nbsp;&nbsp;Preview PDF
                </label>
                <br></br>
                <label
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Fira Sans',
                    fontWeight: '300',
                  }}
                >
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={this.handleOptionChange}
                  />
                  &nbsp;&nbsp;Send PDF to an email address
                </label>
              </div>
            </Col>
            <Col sm="1"></Col>
          </Row>
          {selectedOption === 'option2' && (
            <Row className="mt-1">
              <Col sm="3"></Col>
              <Col sm="7">
                <span>
                  <Label
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                    }}
                  >
                    Please enter an Email address&nbsp;
                    <span
                      style={{
                        color: '#c02b0a',
                        fontSize: '12px',
                        fontWeight: '400',
                        fontStyle: 'italic',
                      }}
                    >
                      (Required)
                    </span>
                  </Label>
                  <Input
                    type="text"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      border: '1px solid #767676',
                      fontFamily: 'Fira Sans',
                    }}
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                  <Label
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                    }}
                    className="mt-3"
                  >
                    Email message
                  </Label>
                  <Input
                    type="textarea"
                    style={{
                      height: '288px',
                      fontSize: '14px',
                      fontWeight: '400',
                      border: '1px solid #767676',
                      fontFamily: 'Fira Sans',
                    }}
                    name="message"
                    id="message"
                    value={message}
                    onChange={this.handleInputChange}
                  />
                </span>
              </Col>
            </Row>
          )}
          <Row className="mt-3 mb-5">
            <Col sm="3"></Col>
            <Col sm="8">
              <button
                type="button"
                className="custom-button"
                onClick={this.generatePDF}
              >
                {selectedOption === 'option2' ? 'Send PDF' : 'Generate PDF'}
              </button>
              {this.state.loading && (
                <div
                  className="loading-spinner"
                  style={{ marginLeft: '10px' }}
                />
              )}
            </Col>
            <Col sm="1"></Col>
          </Row>
          {/* <div style={{ display: 'none' }}> */}

          {/* <div id="contentDiv">
              <h2>{this.state.content}</h2>
              <p>{this.state.text}</p>
            </div>
          </div> */}
          {showPdfContent && (
            <div
              id="pdf-content"
              style={{
                padding: '20px',
                fontFamily: 'Fira Sans',
                fontSize: '5px',
                pageBreakBefore: 'always',
              }}
            >
              <div
                className="wrapped-generated"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          )}
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  listQuestionDetailsWithImageToBase64:
    myStoryOperations.listQuestionDetailsWithImageToBase64,
  sendPDF: myStoryOperations.sendPDF,
  generatePDF: myStoryOperations.generatePDF,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pdf)
