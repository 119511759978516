import React, { useEffect, useState } from 'react'
import { Input, Col, Row, Label } from 'reactstrap'
import request from '../../utils/request.js'
import * as API_URL from '../../env.js'
import { myStoryOperations } from '../../state/ducks/story/index.js'
import { connect } from 'react-redux'
import './sendQuestion.css'

const AddAnotherQuestios = (props) => {
  const { onAdd, handleRemoveCol, setQuestion = () => {} } = props || {}
  const [state, setState] = useState({
    selectedOption: '',
    showDetails: false,
    selectedCategory: '',
    showSecondDropdown: false,
    selectedQuestionText: '',
    selectedQuestionDescription: '',
    category: [],
    question: [],
    language: [],
    selectedQuestion: '',
    selectedGiftUserData: {},
    selectedGiftUserOrderData: [],
    selectedQuestionData: '',
    selectedLanguage: '',
  })

  const {
    selectedOption,
    showDetails,
    selectedCategory,
    category,
    question,
    language,
    selectedQuestionData,
    selectedLanguage,
  } = state

  const GiftUserData = async () => {
    try {
      const { loggedInUserId } = props
      if (!loggedInUserId) {
        console.log('No user ID found')
        return
      }

      const options = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/gift-order-details`,
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'),
        },
      }
      let response = await request(options)
      setState({ ...state, selectedGiftUserData: response.payload.user })
      setState({
        ...state,
        selectedGiftUserOrderData: response.payload.userOrders,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const loadLocalStorageData = async () => {
    //Store category
    let category = localStorage.getItem('category')
    if (category) {
      category = JSON.parse(category)
      setState((prevState) => ({
        ...prevState,
        category,
      }))
    } else {
      let response = await props.listCategory()
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload)
        localStorage.setItem('category', searchString)
        category = JSON.parse(searchString)
        setState((prevState) => ({
          ...prevState,
          category,
        }))
      }
    }

    let language = localStorage.getItem('language')
    if (language) {
      language = JSON.parse(language)
      setState((prevState) => ({
        ...prevState,
        language,
      }))
    } else {
      let response = await props.listLanguage()
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload)
        localStorage.setItem('language', searchString)
        language = JSON.parse(searchString)
        setState((prevState) => ({
          ...prevState,
          language,
        }))
      }
    }
  }

  useEffect(() => {
    loadLocalStorageData()
  }, [])

  const handleOptionChange = (e) => {
    const selectedOption = e.target.value
    setState((prevState) => ({
      ...prevState,
      selectedOption,
      showDetails: true,
      selectedCategory: '',
      selectedQuestionText: '',
      selectedQuestionDescription: '',
      selectedQuestion: '',
      selectedLanguage: '',
      selectedQuestionData: '',
      category: [],
      question: [],
    }))
  }

  const handleLanguageChange = async (e) => {
    const selectedLanguage = e.target.value
    let category = localStorage.getItem('category')
    category = JSON.parse(category)
    const language = selectedLanguage === 'English' ? category : []
    setState((prevState) => ({
      ...prevState,
      selectedLanguage,
      category: language,
      selectedQuestionData: selectedLanguage === 'English',
    }))
    let response = await props.listQuestionByLanguage({
      language_name: selectedLanguage,
    })
    if (response.payload && response.payload.length !== 0) {
      const searchString = JSON.stringify(response.payload)
      localStorage.setItem('question', searchString)
      const question = JSON.parse(searchString)
      setState((prevState) => ({
        ...prevState,
        searchString,
        question,
      }))
    } else {
      localStorage.setItem('question', JSON.stringify([]))
      setState((prevState) => ({
        ...prevState,
        question: [],
      }))
    }
  }
  const handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value
    setState((prevState) => ({
      ...prevState,
      selectedCategory,
      showSecondDropdown: selectedCategory !== '',
    }))

    let response = await props.listQuestionByCategory({
      category_id: selectedCategory,
    })

    if (response.payload && response.payload.length !== 0) {
      const searchString = JSON.stringify(response.payload)
      localStorage.setItem('question', searchString)
      const question = JSON.parse(searchString)
      setState((prevState) => ({
        ...prevState,
        searchString,
        question,
      }))
    } else {
      localStorage.setItem('question', JSON.stringify([]))
      setState((prevState) => ({
        ...prevState,
        question: [],
      }))
    }
  }

  const handleOptionQuestionChange = async (e) => {
    const selectedQuestionId = e.target.value
    try {
      const options = {
        url:
          API_URL.BACKEND_SERVICE_API_URL +
          `/question/get-Question-details/${selectedQuestionId}`, // change this static id to wooCommerce UserId
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'), // change this token to wooCommerce user token
        },
      }
      let response = await request(options)
      const selectedQuestionData = response.payload
      setQuestion(selectedQuestionData)
      setState({ ...state, selectedQuestionData })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Row className="mt-3">
      <Col md="12">
        <div
          style={{
            background: '#8A1A75',
            padding: '10px',
            position: 'relative',
            height: '40px',
          }}
        >
          <a href="javascript:;" className="rwmb-clone-icon"></a>
          &nbsp;
          {onAdd === true ? (
            <span
              onClick={handleRemoveCol}
              style={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '10px',
                cursor: 'pointer',
              }}
            >
              Remove
            </span>
          ) : (
            <span></span>
          )}
        </div>
        <div
          style={{
            border: '1px solid #eee',
            background: '#f8f9fa',
            padding: '3%',
          }}
        >
          <Label style={{ font: 'normal normal 300 1.1rem / 1.5 Martel' }}>
            Please select
          </Label>
          <div className="mt-2">
            <label style={{ font: 'normal normal 300 1.1rem/1.5 Fira Sans' }}>
              <input
                type="radio"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={handleOptionChange}
              />
              &nbsp;Write your own question
            </label>
            <br></br>
            <label style={{ font: 'normal normal 300 1.1rem/1.5 Fira Sans' }}>
              <input
                type="radio"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={handleOptionChange}
              />
              &nbsp;Select a question from our list
            </label>
          </div>
          <Input
            type="select"
            id="language_name"
            name="language_name"
            style={{
              backgroundColor: '#F4E4EF',
              letterSpacing: '0.122rem',
              border: '0',
              padding: '12px 30px',
              borderRadius: '50px',
              margin: '10px 0 0',
              font: 'normal normal normal 15px/17px Fira Sans',
              fontWeight: '400',
              color: '#43107E',
            }}
            onChange={handleLanguageChange}
            value={selectedLanguage}
          >
            <option value="">SELECT A LANGUAGE</option>
            {language.map((data) => (
              <option>{data.language_name}</option>
            ))}
          </Input>
          {showDetails && (
            <>
              {selectedOption === 'option1' ? (
                <div className="mt-2">
                  <Label style={{ fontSize: '17px' }}>Question</Label>
                  <Input
                    type="text"
                    name="question"
                    id="question"
                    onChange={(e) => {
                      setState({ ...state, question: e.target.value })
                      setQuestion(e.target.value)
                    }}
                  />
                  <Label style={{ fontSize: '17px' }} className="mt-3">
                    Question Description
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    onChange={(e) =>
                      setState({ ...state, description: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div>
                  <Label style={{ fontSize: '17px' }} className="mt-2">
                    Select a Category
                  </Label>
                  <Input
                    type="select"
                    id="select_category"
                    name="select_category"
                    style={{
                      backgroundColor: '#F4E4EF',
                      width: '100%',
                      letterSpacing: '0.122rem',
                      border: '0',
                      padding: '12px 30px',
                      borderRadius: '50px',
                      font: 'normal normal normal 15px/17px Fira Sans',
                      fontWeight: '400',
                    }}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    <option value="">SELECT A CATEGORY</option>
                    {category.map((cat, i) => (
                      <option key={i} value={cat.id}>
                        {cat.category_name}
                      </option>
                    ))}
                  </Input>
                  <Label style={{ fontSize: '17px' }} className="mt-2">
                    Select a Question
                  </Label>
                  <Input
                    type="select"
                    id="second_dropdown"
                    name="second_dropdown"
                    style={{
                      backgroundColor: '#F4E4EF',
                      width: '100%',
                      letterSpacing: '0.122rem',
                      border: '0',
                      padding: '12px 30px',
                      borderRadius: '50px',
                      font: 'normal normal normal 15px/17px Fira Sans',
                      fontWeight: '400',
                    }}
                    onChange={handleOptionQuestionChange}
                  >
                    <option value="">SELECT A QUESTION</option>
                    {question.map((question, i) => (
                      <option key={i} value={question.id}>
                        {' '}
                        {question.question}{' '}
                      </option>
                    ))}
                  </Input>
                  <Label style={{ fontSize: '17px' }} className="mt-2">
                    Question<span style={{ color: '#F61C04' }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="question"
                    id="question"
                    value={
                      selectedQuestionData ? selectedQuestionData.question : ''
                    }
                  />
                  <Label style={{ fontSize: '17px' }} className="mt-3">
                    Question Description
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    value={
                      selectedQuestionData
                        ? selectedQuestionData.question_description
                        : ''
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  addQuestion: myStoryOperations.addQuestion,
  listCategory: myStoryOperations.listCategory,
  listLanguage: myStoryOperations.listLanguage,
  sendQuestion: myStoryOperations.sendQuestion,
  listQuestionByLanguage: myStoryOperations.listQuestionByLanguage,
  listQuestionByCategory: myStoryOperations.listQuestionByCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAnotherQuestios)
