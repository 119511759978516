import { combineReducers } from 'redux'
import * as types from './types'
import { createReducer } from '../../utils'

const listReducer = createReducer([])({
  [types.FETCH_MY_STORY_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_RENEWAL_STORY_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_QUESTION_LIST_BY_CATEGORY_LIST]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_SUBSCRIPTION_PRICING_LIST]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_DISCOUNT_CODE_LIST]: (state, action) => {
    return action.payload.payload
  },
  [types.INSERT_MY_STORY_COMPLETED]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.FETCH_DISCOUNT_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.INSERT_SUBSCRIPTION]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.UPDATE_MY_STORY_COMPLETED]: (state, action) => {
    const myStory = action.payload.payload
    return state.map((e) => (e.id === myStory.id ? myStory : e))
  },
  [types.FETCH_QUESTION_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.INSERT_QUESTION_COMPLETED]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.UPDATE_QUESTION_COMPLETED]: (state, action) => {
    const question = action.payload.payload
    return state.map((e) => (e.id === question.id ? question : e))
  },
  [types.DELETE_QUESTION_COMPLETED]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.FETCH_CATEGORY_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_QUESTION_LIST_BY_CATEGORY_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_QUESTION_LIST_BY_LANGUAGE_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_CONTRIBUTOR_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.INSERT_CONTRIBUTOR_COMPLETED]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.UPDATE_CONTRIBUTOR_COMPLETED]: (state, action) => {
    const contributor = action.payload.payload
    return state.map((e) => (e.id === contributor.id ? contributor : e))
  },
  [types.DELETE_CONTRIBUTOR_COMPLETED]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.FETCH_LIST_COMPLETED]: (state, action) => {
    return action.payload.payload
  },
  [types.FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR]: (state, action) => {
    return action.payload.payload
  },
  [types.PAYMENT_SUCCESSFULLY]: (state, action) => {
    return action.payload.payload
  },
  [types.RENEWAL_PAYMENT_SUCCESSFULLY]: (state, action) => {
    return action.payload.payload
  },
  [types.PDF_SEND]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.GENERATE_PDF]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.INSERT_ORDERBOOK]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.INSERT_ADDITIONAL_ORDERBOOK]: (state, action) => {
    const event = action.payload.payload
    state.push(event)
    return state.slice()
  },
  [types.FETCH_ADDITIONAL_ORDERBOOK_LIST]: (state, action) => {
    return action.payload.payload
  },
})

export default combineReducers({
  list: listReducer,
})
