import React, { Fragment } from 'react'
import { Container, CardBody, Col, Form, Row, Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as API_URL from '../../env.js'
import request from '../../utils/request'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { emailFrequencyOperations } from '../../state/ducks/email'
import GoogleFontLoader from 'react-google-font-loader'

class Emails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
      selectedUserData: {},
      selectedMenu: '',
    }
  }

  handleOptionChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    })
  }

  async componentDidMount() {
    try {
      const { loggedInUserId } = this.props
      if (!loggedInUserId) {
        console.log('No user ID found')
        return
      }
      const options = {
        url:
          API_URL.BACKEND_SERVICE_API_URL +
          `/user/get-user-data/${loggedInUserId}`, // change this static id to wooCommerce UserId
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'), // change this token to wooCommerce user token
        },
      }
      let response = await request(options)
      if (response.status === 200) {
        this.setState({
          selectedUserData: {
            user_id: response.payload.user_id,
            first_name: response.payload.first_name,
            last_name: response.payload.last_name,
            user_email: response.payload.user_email,
            frequency: response.payload.frequency,
          },
          selectedOption: response.payload.frequency, // Set selected option based on the frequency received
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  onUpdate = async (e) => {
    e.preventDefault()
    try {
      const { selectedOption, selectedUserData } = this.state
      if (!selectedUserData) {
        throw new Error('User data is missing.')
      }
      let updateResponse = await this.props.updateEmailFrequency({
        id: selectedUserData.user_id,
        frequency: selectedOption,
      })
      toast.success(updateResponse.message, { autoClose: 10000 })
      window.open('/emails/', '_self')
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message, { autoClose: 10000 })
      } else {
        toast.error('An error occurred while updating.', { autoClose: 10000 })
      }
      console.log(err)
    }
  }

  handleMenuClick = (menu) => {
    this.setState({ selectedMenu: menu })
  }

  onBtnClick = () => {
    window.open('/my-stories/', '_self')
  }

  render() {
    const { selectedMenu } = this.state
    const ulStyle = {
      display: 'flex',
      color: '#a186be',
      listStyle: 'none',
      padding: 0,
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: '300',
      fontFamily: 'Fira Sans',
      fontSize: '.8rem',
    }

    const spanStyle = {
      background: '#fdc1ea',
      display: 'inline-block',
      height: '20px',
      width: '1px',
      margin: '0 20px',
    }

    const liStyle = {
      cursor: 'pointer',
      paddingBottom: '5px', // Add some padding to make the border more visible
    }
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onUpdate}>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 300 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  My Account
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
                <ul className="mt-4 myAccountMenu" style={ulStyle}>
                  <li
                    style={
                      selectedMenu === 'billing' ? { ...liStyle } : liStyle
                    }
                    onClick={() => this.handleMenuClick('billing')}
                  >
                    <Link
                      to="/billing/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      BILLING
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === 'my-details' ? { ...liStyle } : liStyle
                    }
                    onClick={() => this.handleMenuClick('my-details')}
                  >
                    <Link
                      to="/my-details/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      MY DETAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    className="current-item"
                    style={selectedMenu === 'emails' ? { ...liStyle } : liStyle}
                    onClick={() => this.handleMenuClick('emails')}
                  >
                    <Link
                      to="/emails/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      EMAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === 'reset-password'
                        ? { ...liStyle }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick('reset-password')}
                  >
                    <Link
                      to="/reset-password/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      RESET PASSWORD
                    </Link>
                  </li>
                </ul>
              </Row>
              <Row>
                {/* <Col md='2'></Col> */}
                <Col md="12" style={{ maxWidth: '950px', margin: '0 auto' }}>
                  <div
                    className="billingMyStoryTitle"
                    style={{
                      fontSize: '17px',
                      fontWeight: '400',
                      fontFamily: 'Martel',
                      borderBottom: '1px solid #fdc1ea',
                      paddingBottom: '12px',
                      marginBottom: '30px',
                    }}
                  >
                    Question frequency
                  </div>
                </Col>
                <Col md="12" style={{ maxWidth: '950px', margin: '0 auto' }}>
                  <Row>
                    <Col xs={12}>
                      <Label
                        style={{
                          font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        }}
                      >
                        How often would you like to recieve question prompts by
                        email?
                      </Label>
                      <div style={{ padding: '2%' }}>
                        <form>
                          <label
                            style={{
                              font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                            }}
                          >
                            <input
                              type="radio"
                              value="never"
                              checked={this.state.selectedOption === 'never'}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;Never
                          </label>
                          <br />
                          <label
                            style={{
                              font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                            }}
                          >
                            <input
                              type="radio"
                              value="once a day"
                              checked={
                                this.state.selectedOption === 'once a day'
                              }
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;Once a day
                          </label>
                          <br />
                          <label
                            style={{
                              font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                            }}
                          >
                            <input
                              type="radio"
                              value="once a week"
                              checked={
                                this.state.selectedOption === 'once a week'
                              }
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;Once a week
                          </label>
                          <br />
                          <label
                            style={{
                              font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                            }}
                          >
                            <input
                              type="radio"
                              value="every two weeks"
                              checked={
                                this.state.selectedOption === 'every two weeks'
                              }
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;Every two weeks
                          </label>
                          <br />
                          <label
                            style={{
                              font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                            }}
                          >
                            <input
                              type="radio"
                              value="once a month"
                              checked={
                                this.state.selectedOption === 'once a month'
                              }
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;Once a month
                          </label>
                        </form>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <button type="submit" className="custom-button">
                        {'Update frequency'}{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="feather feather-chevron-right"
                          fill="none"
                          height="24"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <span
                  style={{
                    borderBottom: '1px solid #ccc',
                    height: '2px',
                    width: '880px',
                    margin: '10px auto',
                  }}
                ></span>
              </Row>
              <Row className="mt-1">
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <button
                    type="submit"
                    style={{ margin: '0 auto' }}
                    className="custom-button"
                    onClick={this.onBtnClick}
                  >
                    {'View my stories '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="feather feather-chevron-right"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </button>
                </Col>
              </Row>
              <Row className="mt-4 mb-5">
                <ul style={ulStyle}>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com">BUY A STORY</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com">GIFT A STORY</Link>
                  </li>
                </ul>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  updateEmailFrequency: emailFrequencyOperations.updateEmailFrequency,
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails)
